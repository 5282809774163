






























import Vue from "vue";
import coreApiWorkerClient from "@/services/apis/coreApiWorkerClient";
import DataContainer from "@common/modules/vuetifyx/common/DataContainer";
import userManager from "@/services/userManager";

export default Vue.extend({
  data() {
    return {
      self: this,
      userManager,
      month: parseInt(this.$route.params.month, 10),
      currentTab: "",
      tabs: {
        teams: {
          label: "Teams",
        },
        users: {
          label: "Users",
        },
        team_committed_data: {
          label: "Dữ liệu chốt (Team)",
        },
        user_committed_data: {
          label: "Dữ liệu chốt (User)",
        },
      },
      item: {},
      refreshing: true,
      monthPickerOptions: {
        attrs: {
          label: "Chọn tháng",
        },
        content: {
          dpAttrs: {
            type: "month",
          },
          format: "YYYY-MM",
          setDefaultValue: true,
        },
      },
      itemEditBtnOptions: {
        attrs: {
          small: true,
          color: "primary",
          dark: true,
          class: "ml-2 mb-4",
        },
        content: {
          icon: "mdi-cog-outline",
          text: "Cài đặt tháng",
        },
        target: {
          tooltip: {
            content: {
              text: "Setting",
            },
          },
          dialog: {
            attrs: {
              width: "800px",
            },
            handlers: {
              initialize() {
                this.options.content.buttons.save.states.enabled = new DataContainer(false);
              },
            },
            content: {
              toolbar: {
                title: "Cài đặt tháng",
              },
              content: {
                type: "XForm",
                makeAttrs(attrs: any = {}, dialog) {
                  const { item } = dialog.context().context();
                  attrs.xOptions = {
                    content: {
                      sections: {
                        teamKpi: {
                          header: "Tham số & công thức (team kpi)",
                          fields: {
                            teamBonus: {
                              type: "number",
                              attrs: {
                                label: "Thưởng team",
                              },
                            },
                            teamKpiFormula: {
                              type: "textLarge",
                              attrs: {
                                label: "Công thức tính KPI team",
                              },
                            },
                            teamQualityRevenueFormula: {
                              type: "textLarge",
                              attrs: {
                                label: "Công thức tính doanh số hiệu quả team",
                              },
                            },
                            teamMinimumQualityRevenueFormula: {
                              type: "textLarge",
                              attrs: {
                                label: "Công thức tính doanh số hiệu quả tối thiểu team",
                              },
                            },
                            teamQualityRevenuePerUserThreshold: {
                              type: "number",
                              attrs: {
                                label: "Ngưỡng doanh số hiệu quả team / người",
                              },
                            },
                            teamCustomVariableItems: {
                              type: "XArrayInput",
                              attrs: {
                                label: "Tham số tùy biến khi tính toán Team KPI",
                                xOptions: {
                                  content: {
                                    defaultExpandDisabled: true,
                                    itemLabel: (item, i) => item.name || `Tham số ${i + 1}`,
                                    itemProperties: {
                                      name: {
                                        type: "text",
                                        attrs: {
                                          label: "Tên",
                                          required: true,
                                        },
                                      },
                                      formula: {
                                        type: "textLarge",
                                        attrs: {
                                          label: "Công thức",
                                          required: true,
                                        },
                                      },
                                      description: {
                                        type: "textLarge",
                                        attrs: {
                                          label: "Mô tả",
                                        },
                                      },
                                    },
                                    template: {
                                      formOptions: {
                                        content: {
                                          colLength: 12,
                                        },
                                      },
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                        userSalary: {
                          header: "Tham số & công thức (lương user)",
                          fields: {
                            workingStartDay: {
                              type: "XDateTimePicker",
                              attrs: {
                                label: "Ngày bắt đầu làm việc",
                                xOptions: {
                                  content: {
                                    resultFilter: (m) => m.startOf("day"),
                                  },
                                },
                              },
                            },
                            totalWorkingDay: {
                              type: "number",
                              attrs: {
                                label: "Tổng ngày làm việc",
                              },
                            },
                            lunchAllowancePerDay: {
                              type: "number",
                              attrs: {
                                label: "Phụ cấp ăn trưa / ngày",
                              },
                            },
                            returnedSocialInsuranceFee: {
                              type: "number",
                              attrs: {
                                label: "Trả lại BHXH",
                              },
                            },
                            ctBirthdayBonus: {
                              type: "number",
                              attrs: {
                                label: "Thưởng sinh nhật chính thức",
                              },
                            },
                            tvBirthdayBonus: {
                              type: "number",
                              attrs: {
                                label: "Thưởng sinh nhật thử việc",
                              },
                            },
                            baseSalaryFormula: {
                              type: "textLarge",
                              attrs: {
                                label: "Công thức tính lương cứng",
                              },
                            },
                            redLineFineFormula: {
                              type: "textLarge",
                              attrs: {
                                label: "Công thức tính phạt lằn ranh đỏ",
                              },
                            },
                            kpiBonusFormula: {
                              type: "textLarge",
                              attrs: {
                                label: "Công thức tính thưởng KPI",
                              },
                            },
                            topKpiBonusCheckFormula: {
                              type: "textLarge",
                              attrs: {
                                label: "Công thức check thưởng KPI doanh số người tốt nhất",
                              },
                            },
                            topKpiBonusFormula: {
                              type: "textLarge",
                              attrs: {
                                label: "Công thức tính thưởng KPI doanh số người tốt nhất",
                              },
                            },
                            ctUpBonusFormula: {
                              type: "textLarge",
                              attrs: {
                                label: "Công thức tính thưởng nhân viên lên chính thức",
                              },
                            },
                            leaderCtUpBonusFormula: {
                              type: "textLarge",
                              attrs: {
                                label: "Công thức tính thưởng leader lên chính thức",
                              },
                            },
                            bonusArrearsFormula: {
                              type: "textLarge",
                              attrs: {
                                label: "Công thức tính truy thu thưởng",
                              },
                            },
                            teamKpiBonusFormula: {
                              type: "textLarge",
                              attrs: {
                                label: "Công thức tính thưởng KPI team",
                              },
                            },
                            leadKpiBonusFormula: {
                              type: "textLarge",
                              attrs: {
                                label: "Công thức tính thưởng KPI lead",
                              },
                            },
                            tvKpiBonusFormula: {
                              type: "textLarge",
                              attrs: {
                                label: "Công thức tính thưởng KPI thử việc",
                              },
                            },
                            otherKpiBonusFormula: {
                              type: "textLarge",
                              attrs: {
                                label: "Công thức tính thưởng KPI khác",
                              },
                            },
                            leadKpiFineFormula: {
                              type: "textLarge",
                              attrs: {
                                label: "Công thức tính phạt KPI lead",
                              },
                            },
                            ctKpiFineFormula: {
                              type: "textLarge",
                              attrs: {
                                label: "Công thức tính phạt KPI chính thức + phó nhóm",
                              },
                            },
                            productReturnCheckFormula: {
                              type: "textLarge",
                              attrs: {
                                label: "Công thức check return NSP",
                              },
                            },
                            productReturnFormula: {
                              type: "textLarge",
                              attrs: {
                                label: "Công thức tính DSDK return NSP",
                              },
                            },
                            rdProductGroupCheckFormula: {
                              type: "textLarge",
                              attrs: {
                                label: "Công thức check test NSP",
                              },
                            },
                            rdProductGroupFormula: {
                              type: "textLarge",
                              attrs: {
                                label: "Công thức tính DSDK test NSP",
                              },
                            },
                            qualityRevenueFormula: {
                              type: "textLarge",
                              attrs: {
                                label: "Công thức tính Doanh số hiệu quả",
                              },
                            },
                            userCustomVariableItems: {
                              type: "XArrayInput",
                              attrs: {
                                label: "Tham số tùy biến khi tính toán lương nhân viên",
                                xOptions: {
                                  content: {
                                    defaultExpandDisabled: true,
                                    itemLabel: (item, i) => item.name || `Tham số ${i + 1}`,
                                    itemProperties: {
                                      name: {
                                        type: "text",
                                        attrs: {
                                          label: "Tên",
                                          required: true,
                                        },
                                      },
                                      formula: {
                                        type: "textLarge",
                                        attrs: {
                                          label: "Công thức",
                                          required: true,
                                        },
                                      },
                                      description: {
                                        type: "textLarge",
                                        attrs: {
                                          label: "Mô tả",
                                        },
                                      },
                                    },
                                    template: {
                                      formOptions: {
                                        content: {
                                          colLength: 12,
                                        },
                                      },
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                        calculate: {
                          header: "Tính toán",
                          fields: {
                            committedDataTime: {
                              type: "XTimePicker",
                              attrs: {
                                label: "Thời gian chốt dữ liệu",
                                xOptions: {
                                  content: {
                                    textFieldAttrs: {
                                      readonly: true,
                                      disabled: true,
                                    },
                                  },
                                },
                              },
                            },
                            commitData: {
                              type: "boolean",
                              attrs: {
                                label: "Chốt dữ liệu",
                              },
                            },
                            recalculate: {
                              type: "boolean",
                              attrs: {
                                label: "Tính lại lương",
                              },
                              ext: {
                                condition() {
                                  return item.committedDataTime;
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                  };
                  attrs.xModel = dialog.options.content.buttons.save.states.enabled;
                  attrs.xContext = dialog;
                  attrs.xData = new DataContainer(item);
                  return attrs;
                },
              },
              buttons: {
                save: {
                  content: {
                    text: "Cập nhật",
                  },
                  states: {},
                  on: {
                    async xClick({ self }) {
                      const dialog = self.context();
                      const btn = dialog.context();
                      const { month } = btn.context();
                      const form = dialog.contentContainer.value;
                      const data = form.getData();
                      self.loading.value = true;
                      let result = await coreApiWorkerClient.call(
                        "salaryMonthInfos",
                        "updateByMonth",
                        {
                          month,
                        },
                        {
                          data,
                        }
                      );
                      if (result) {
                        if (data.commitData) {
                          result = await coreApiWorkerClient.call("salaries", "commitData", {
                            month,
                          });
                        }
                      }
                      if (result) {
                        if (data.recalculate) {
                          result = await coreApiWorkerClient.call("salaries", "calculate", {
                            month,
                          });
                        }
                      }
                      self.loading.value = false;
                      if (result) {
                        location.reload();
                      }
                    },
                  },
                },
              },
            },
          },
        },
      },
    };
  },
  computed: {},
  watch: {
    month() {
      this.$router.push(`/app/salaries/${this.month}/${this.currentTab.split("/").pop()}`);
    },
  },
  methods: {
    async refresh() {
      this.refreshing = true;
      const item = await coreApiWorkerClient.call("salaryMonthInfos", "findByMonth", {
        month: this.month,
      });
      this.item = item || {};
      this.refreshing = false;
    },
  },
  created() {
    this.refresh();
  },
});
